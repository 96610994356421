<template>
  <div>
    <BaseModal
      @close="fechar()"
      :classesAdicionaisModal="'modal-pesquisa rounded overflow-x-hidden w-60 w-sm-100 position-relative'"
      :classesAdicionaisBackDrop="'align-items-start'"
      :classesAdicionaisHeader="'d-none'"
      :classesAdicionaisFooter="'d-none'"
      :classesAdicionaisBody="'py-2 overflow-x-hidden'"
      id="modalConferenciaEdicao"
    >
      <div slot="body" class="col-12">
        <template v-if="edicaoIndividual">
          <DevolucaoConferenciaEdicaoIndividual :transacaoEdicao="transacaoEdicao" @close="fechar" />
        </template>
        <template v-else>
          <DevolucaoConferenciaEdicaoEmMassa
            :transacoesEdicao="produtosEdicao"
            :quantidadeItensSelecionados="quantidadeItensSelecionados"
            @close="fechar"
          />
        </template>
      </div>
    </BaseModal>
  </div>
</template>

<script>
  import DevolucaoConferenciaEdicaoIndividual from './DevolucaoConferenciaEdicaoIndividual';
  import DevolucaoConferenciaEdicaoEmMassa from './DevolucaoConferenciaEdicaoEmMassa';

  export default {
    name: 'DevolucaoConferenciaEdicao',

    components: {
      DevolucaoConferenciaEdicaoIndividual,
      DevolucaoConferenciaEdicaoEmMassa,
    },

    props: {
      quantidadeItensSelecionados: {
        type: Number,
        required: true,
        default: 1,
      },
      produtosEdicao: {
        type: Array,
        required: false,
      },
    },

    computed: {
      edicaoIndividual() {
        return this.produtosEdicao.length == 1;
      },

      transacaoEdicao() {
        if (this.edicaoIndividual) {
          return this.produtosEdicao[0];
        } else {
          return -1;
        }
      },
    },

    methods: {
      fechar() {
        this.$emit('fechar');
      },
    },
  };
</script>
