<template>
  <div @keydown.esc="fechar()">
    <div class="mb-4">
      <div class="mb-3 mt-3">
        <p class="font-weight-bold">Escolha qual informação deseja alterar</p>
      </div>

      <div class="row">
        <div class="col-6">
          <label for="selectCfop">CFOP</label>
          <select v-model="CamposEditaveis.Cfop" class="form-control form-control-sm" id="selectCfop">
            <option v-for="cfop in cfopsPorOperacao" :value="cfop.Codigo" :key="cfop.Id"
              >{{ cfop.Codigo }} - {{ cfop.Descricao }}
            </option>
          </select>
        </div>
        <div v-if="csosns" class="col-6">
          <label for="selectCsosn">CSOSN</label>
          <select v-model="CamposEditaveis.IcmsCstCsosn" class="form-control form-control-sm" id="selectCsosn">
            <option v-for="csosn in csosns" :value="csosn.Codigo" :key="csosn.Id"
              >{{ csosn.Codigo }} - {{ csosn.Descricao }}
            </option>
          </select>
        </div>
        <div v-else class="col-6">
          <label for="selectCstIcms">CST ICMS</label>
          <select v-model="CamposEditaveis.IcmsCstCsosn" class="form-control form-control-sm" id="selectCstIcms">
            <option v-for="icms in cstsIcms" :value="icms.Codigo" :key="icms.Id"
              >{{ icms.Codigo }} - {{ icms.Descricao }}
            </option>
          </select>
        </div>
      </div>
      <div class="row mt-3">
        <div v-if="cstsPisCofins" class="col-6">
          <label for="selectPisCofinsCst">CST PIS e COFINS</label>
          <select v-model="CamposEditaveis.PisCofinsCst" class="form-control form-control-sm" id="selectPisCofinsCst">
            <option v-for="pisCofins in cstsPisCofins" :value="pisCofins.Codigo" :key="pisCofins.Id"
              >{{ pisCofins.Codigo }} - {{ pisCofins.Descricao }}
            </option>
          </select>
        </div>
        <div v-if="cstsIpi" class="col-6">
          <label for="selectCstIpi">CST IPI</label>
          <select v-model="CamposEditaveis.IpiCst" class="form-control form-control-sm" id="selectCstIpi">
            <option v-for="ipi in cstsIpi" :value="ipi.Codigo" :key="ipi.Id">{{ ipi.Codigo }} - {{ ipi.Descricao }}</option>
          </select>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12">
          <label for="selectNcm">NCM</label>
          <select v-model="CamposEditaveis.Ncm" class="form-control form-control-sm" id="selectNcm">
            <option v-for="ncm in ncms" :value="ncm.Codigo" :key="ncm.Id">{{ ncm.Codigo }} - {{ ncm.Descricao }}</option>
          </select>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12">
          <label for="selectCest">CEST</label>
          <select v-model="CamposEditaveis.Cest" class="form-control form-control-sm" id="selectCest">
            <option v-for="cest in cests" :value="cest.Codigo" :key="cest.Id">{{ cest.Codigo }} - {{ cest.Descricao }}</option>
          </select>
        </div>
      </div>
    </div>
    <div class="modal-footer pl-0 pr-0 justify-content-between">
      <span id="span-descricao-produto" class="text-primary"
        ><em
          >{{ Produto.Descricao }}
          <span class="small"
            >(<span v-if="Produto.Referencia">Ref:{{ Produto.Referencia }} |</span> Cód:{{ Produto.CodigoProduto }})</span
          >
          está sendo alterado</em
        >
      </span>
      <div class="pt-2 div-botoes-edicao">
        <button id="button-fechar" type="button" class="btn btn-default ml-2" aria-label="Close" @click="fechar">Fechar</button>
        <button id="button-salvar" type="button" class="btn btn-primary ml-2" @click="salvar">Salvar</button>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';

  export default {
    name: 'DevolucaoConferenciaEdicaoIndividual',

    props: {
      transacaoEdicao: {
        type: Number,
        required: true,
        default: null,
      },
    },

    data() {
      return {
        Produto: {},
        CamposEditaveis: {
          Cest: null,
          Cfop: null,
          CstIpi: null,
          PisCofinsCst: null,
          IpiCst: null,
          IcmsCstCsosn: null,
          Ncm: null,
        },
        cfopsPorOperacao: [],
      };
    },

    mounted() {
      this.obterProdutoEdicao();
      this.atualizarValoresEmTela();
      this.listarCfopPorOperacao();
    },

    computed: {
      ...mapState({
        notasConferencia: (state) => state.devolucao.Conferencia.Referenciados,
        produtos: (state) => state.devolucao.Produtos,
        cests: (state) => state.devolucao.Configuracoes.listasGerais.Cests,
        cfops: (state) => state.devolucao.Configuracoes.listasGerais.Cfops,
        csosns: (state) => state.devolucao.Configuracoes.listasGerais.Csosns,
        cstsIcms: (state) => state.devolucao.Configuracoes.listasGerais.CstsIcms,
        cstsIpi: (state) => state.devolucao.Configuracoes.listasGerais.CstsIpi,
        cstsPisCofins: (state) => state.devolucao.Configuracoes.listasGerais.CstsPisCofins,
        ncms: (state) => state.devolucao.Configuracoes.listasGerais.Ncms,
      }),
    },

    methods: {
      obterProdutoEdicao() {
        var self = this;
        var produtoEdicao = null;
        this.notasConferencia.forEach((nota) => {
          if (produtoEdicao) return;

          produtoEdicao = nota.Itens.find(function (item) {
            return item.Transacao == self.transacaoEdicao;
          });
        });

        this.Produto = produtoEdicao;
        this.Produto.Descricao = this.produtos[produtoEdicao.CodigoProduto].Descricao;
      },

      listarCfopPorOperacao() {
        this.cfopsPorOperacao = this.cfops.filter((c) => c.Codigo.charAt(0) == this.Produto.Cfop.charAt(0));
      },

      atualizarValoresEmTela() {
        this.CamposEditaveis.Cest = this.Produto.Cest;
        this.CamposEditaveis.Cfop = this.Produto.Cfop;
        this.CamposEditaveis.IcmsCstCsosn = this.Produto.IcmsCstCsosn;
        this.CamposEditaveis.Ncm = this.Produto.Ncm;
        this.CamposEditaveis.PisCofinsCst = this.Produto.PisCofinsCst;
        this.CamposEditaveis.IpiCst = this.Produto.IpiCst;
      },

      fechar() {
        this.$emit('close');
      },

      salvar() {
        this.Produto.Cest = this.CamposEditaveis.Cest;
        this.Produto.Cfop = this.CamposEditaveis.Cfop;
        this.Produto.IcmsCstCsosn = this.CamposEditaveis.IcmsCstCsosn;
        this.Produto.Ncm = this.CamposEditaveis.Ncm;
        this.Produto.PisCofinsCst = this.CamposEditaveis.PisCofinsCst;
        this.Produto.IpiCst = this.CamposEditaveis.IpiCst;

        this.fechar();
      },
    },
  };
</script>

<style scoped>
  .div-botoes-edicao {
    min-width: 160px;
  }
</style>
