<template>
  <div @keydown.esc="fechar()">
    <div class="mb-4">
      <div class="mb-3 mt-3">
        <p class="font-weight-bold">Escolha qual informação deseja alterar</p>
      </div>

      <div class="row">
        <div class="col-4">
          <label for="selectCamposEditaveis">Informação desejada</label>
          <select v-model="OpcaoSelecionada" class="form-control form-control-sm" id="selectCamposEditaveis">
            <option value="" disabled selected>Selecione...</option>
            <option v-for="(opcao, index) in CamposEditaveis" :value="opcao" :key="index"> {{ opcao }} </option>
          </select>
        </div>
        <div class="col-8 d-flex flex-column justify-content-end">
          <label>{{ OpcaoSelecionada }}</label>
          <select v-model="OpcaoEditavel" class="form-control form-control-sm" id="selectOpcao">
            <option value="" disabled selected>Selecione...</option>
            <option v-for="(opcao, index) in OpcoesCampoEditavel" :value="opcao" :key="index"
              >{{ opcao.Codigo }} - {{ opcao.Descricao }}</option
            >
          </select>
        </div>
      </div>
    </div>
    <div class="modal-footer pl-0 pr-0 justify-content-between">
      <span id="span-descricao-produto" class="text-primary"
        ><em>{{ quantidadeItensSelecionados }} produtos estão sendo alterados</em>
      </span>
      <div class="pt-2">
        <button id="button-fechar" type="button" class="btn btn-default ml-2" aria-label="Close" @click="fechar">Fechar</button>
        <button id="button-salvar" type="button" class="btn btn-primary ml-2" @click="salvar">Salvar</button>
      </div>
    </div>
  </div>
</template>

<script>
  import swal from '@/common/alerts';
  import { mapState } from 'vuex';
  import { DEVOLUCAO_INFORMACOES_EDITAVEIS } from '@/core/enums/devolucao-informacoes-editaveis.enum';

  export default {
    name: 'DevolucaoConferenciaEdicaoEmMassa',

    props: {
      quantidadeItensSelecionados: {
        type: Number,
        default: 1,
      },

      transacoesEdicao: {
        type: Array,
        required: false,
      },
    },

    data() {
      return {
        Produtos: [],
        OpcaoSelecionada: '',
        CamposEditaveis: [],
        OpcaoEditavel: '',
      };
    },

    mounted() {
      this.listarCamposEditaveis();
      this.listarProdutosEdicao();
    },

    computed: {
      ...mapState({
        notasConferencia: (state) => state.devolucao.Conferencia.Referenciados,
        cests: (state) => state.devolucao.Configuracoes.listasGerais.Cests,
        cfops: (state) => state.devolucao.Configuracoes.listasGerais.Cfops,
        csosns: (state) => state.devolucao.Configuracoes.listasGerais.Csosns,
        cstIcmsDisponiveis: (state) => state.devolucao.Configuracoes.listasGerais.CstsIcms,
        cstsIpi: (state) => state.devolucao.Configuracoes.listasGerais.CstsIpi,
        cstsPisCofins: (state) => state.devolucao.Configuracoes.listasGerais.CstsPisCofins,
        ncms: (state) => state.devolucao.Configuracoes.listasGerais.Ncms,
      }),

      OpcoesCampoEditavel() {
        switch (this.OpcaoSelecionada) {
          case DEVOLUCAO_INFORMACOES_EDITAVEIS.CFOP:
            return this.cfops;
          case DEVOLUCAO_INFORMACOES_EDITAVEIS.CST_ICMS:
            return this.cstIcmsDisponiveis;
          case DEVOLUCAO_INFORMACOES_EDITAVEIS.CSOSN:
            return this.csosns;
          case DEVOLUCAO_INFORMACOES_EDITAVEIS.CST_PIS_COFINS:
            return this.cstsPisCofins;
          case DEVOLUCAO_INFORMACOES_EDITAVEIS.CST_IPI:
            return this.cstsIpi;
          case DEVOLUCAO_INFORMACOES_EDITAVEIS.NCM:
            return this.ncms;
          case DEVOLUCAO_INFORMACOES_EDITAVEIS.CEST:
            return this.cests;
          default:
            return [];
        }
      },
    },

    methods: {
      listarCamposEditaveis() {
        this.CamposEditaveis.push(DEVOLUCAO_INFORMACOES_EDITAVEIS.CFOP);
        this.CamposEditaveis.push(DEVOLUCAO_INFORMACOES_EDITAVEIS.NCM);
        this.CamposEditaveis.push(DEVOLUCAO_INFORMACOES_EDITAVEIS.CEST);

        if (this.cstsPisCofins) this.CamposEditaveis.push(DEVOLUCAO_INFORMACOES_EDITAVEIS.CST_PIS_COFINS);
        if (this.cstsIpi) this.CamposEditaveis.push(DEVOLUCAO_INFORMACOES_EDITAVEIS.CST_IPI);

        if (this.csosns) this.CamposEditaveis.push(DEVOLUCAO_INFORMACOES_EDITAVEIS.CSOSN);
        else this.CamposEditaveis.push(DEVOLUCAO_INFORMACOES_EDITAVEIS.CST_ICMS);
      },

      listarProdutosEdicao() {
        var self = this;
        var produtosEdicao = [];
        var produto = null;

        this.notasConferencia.forEach((nota) => {
          let produtos = nota.Itens.map(function (item) {
            if (self.transacoesEdicao.includes(item.Transacao)) return item;
          });

          produtos.forEach((produto) => {
            if (produto) {
              produtosEdicao.push(produto);
            }
          });
        });

        this.Produtos = produtosEdicao;
      },

      fechar() {
        this.$emit('close');
      },

      salvar() {
        if (!this.validarEdicao()) {
          return;
        }

        switch (this.OpcaoSelecionada) {
          case DEVOLUCAO_INFORMACOES_EDITAVEIS.CFOP:
            this.Produtos.forEach((produto) => {
              produto.Cfop = this.OpcaoEditavel.Codigo;
            });
            break;
          case DEVOLUCAO_INFORMACOES_EDITAVEIS.CST_ICMS:
            this.Produtos.forEach((produto) => {
              produto.IcmsCstCsosn = this.OpcaoEditavel.Codigo;
            });
            break;
          case DEVOLUCAO_INFORMACOES_EDITAVEIS.CSOSN:
            this.Produtos.forEach((produto) => {
              produto.IcmsCstCsosn = this.OpcaoEditavel.Codigo;
            });
            break;
          case DEVOLUCAO_INFORMACOES_EDITAVEIS.CST_PIS_COFINS:
            this.Produtos.forEach((produto) => {
              produto.PisCofinsCst = this.OpcaoEditavel.Codigo;
            });
            break;
          case DEVOLUCAO_INFORMACOES_EDITAVEIS.CST_IPI:
            this.Produtos.forEach((produto) => {
              produto.IpiCst = this.OpcaoEditavel.Codigo;
            });
            break;
          case DEVOLUCAO_INFORMACOES_EDITAVEIS.NCM:
            this.Produtos.forEach((produto) => {
              produto.Ncm = this.OpcaoEditavel.Codigo;
            });
            break;
          case DEVOLUCAO_INFORMACOES_EDITAVEIS.CEST:
            this.Produtos.forEach((produto) => {
              produto.Cest = this.OpcaoEditavel.Codigo;
            });
            break;
          default:
            swal.exibirMensagemErro('Alteração inválida');
            return;
        }

        this.fechar();
      },

      validarEdicao() {
        if (!this.OpcaoEditavel) {
          swal.exibirMensagemErro('Opção selecionada inválida');
          return false;
        }

        return true;
      },
    },
  };
</script>
